/*
window.addEventListener('DOMContentLoaded', (event) => {
    handleFormEvent({ currentTarget: document.querySelector('form') });

    document.querySelector('form').addEventListener('blur', (e) => {
        e.currentTarget.classList.add('was-validated');
    });

    document.querySelector('form').addEventListener('change', (e) => {
        e.currentTarget.classList.add('was-validated');
        handleFormEvent(e);
    });

    document.querySelector('form').addEventListener('submit', (e) => {
        if (!e.currentTarget.checkValidity()) {
            e.preventDefault();
            return false;
        }
    });
});

function handleFormEvent(e) {
    const fd = new FormData(e.currentTarget);

    if (fd.get('response') === 'yes') {
        document.getElementById('mealChicken').removeAttribute('disabled');
        document.getElementById('mealChicken').setAttribute('required', 'required');

        document.getElementById('mealFish').removeAttribute('disabled');
        document.getElementById('mealFish').setAttribute('required', 'required');
    } else {
        document.getElementById('mealChicken').removeAttribute('required');
        document.getElementById('mealChicken').setAttribute('disabled', 'disabled')

        document.getElementById('mealFish').removeAttribute('required');
        document.getElementById('mealFish').setAttribute('disabled', 'disabled')
    }
}*/
(() => {
    Sentry.init({
        dsn: "https://661f01bdac0945c68fecd9e38b59151f@o1376356.ingest.sentry.io/6685383",
        // this assumes your build process replaces `process.env.npm_package_version` with a value
        //release: "ulmer-butz-wedding@" + process.env.npm_package_version,
        integrations: [new Sentry.BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });
})();